<script setup lang="ts">
import { GenericProductProps } from "./GenericProduct.props"
import { optionsSm, optionsMd } from "./GenericProduct.carouselOptions"
import { sm } from "~/utils/breakpoints"

defineProps<GenericProductProps>()

const carouselOptions = computed(() => (sm.value ? optionsSm : optionsMd))
</script>

<template>
  <div
    class="
      padded
      md:grid-container
      flex flex-col
      items-center
      bg-tiffany-10
      md:items-center
    "
  >
    <div class="flex flex-col gap-4 pt-8 md:col-span-5 md:pt-0">
      <p v-if="title" class="dolphin-bold md:whale-bold">
        {{ title }}
      </p>
      <p v-if="description" class="pig">
        {{ description }}
      </p>
    </div>
    <div
      class="
        carousel-container-overflowed
        w-full
        py-8
        md:col-span-6 md:col-start-7
        md:-ml-10 md:-mr-[71px]
        md:w-auto
        md:overflow-x-hidden
        md:pl-10
        md:pr-[71px]
        lg:-mr-[228px]
        lg:pr-[228px]
      "
    >
      <GAItem :id="`generic-product-${$route.params.product}`" :item="products">
        <GAViewItemList>
          <AppCarousel
            v-if="products?.length"
            class="carousel-overflowed select-none"
            :swiper-props="carouselOptions"
            :items="products"
            :theme="carouselOptions?.theme"
            :key="sm ? 1 : 0"
          >
            <template #default="{ item }">
              <ProductTileOverallResults
                v-if="item"
                v-bind="item"
                class="
                  w-[328px]
                  md:w-auto
                  [&_div.utils-button-wrapper]:md:w-3/5
                  [&_div.utils-button-wrapper]:md:self-end
                "
              />
            </template>
          </AppCarousel>
        </GAViewItemList>
      </GAItem>
    </div>
  </div>
</template>
