import aa from "search-insights"
import { createInsightsMiddleware } from "instantsearch.js/es/middlewares"
import { hitsViewed } from "./algolia"

export const insightsAlgoliaMiddleware = (method: string) => {
  return createInsightsMiddleware({
    insightsClient: aa,
    onEvent: (event) => {
      const { insightsMethod, payload } = event
      const productIDs: string[] =
        event?.hits?.map(
          (product): string => `product_${product?.productCode}`
        ) ?? []
      // @ts-ignore Send the event to Algolia
      hitsViewed(insightsMethod, payload.index, method, productIDs)
    }
  })
}
