<script setup lang="ts">
import { DefaultProps } from "./Default.props"
import { iconsSrcMap } from "./Default.iconMapping"

import { sm, md, lg } from "~/utils/breakpoints"

const props = withDefaults(defineProps<DefaultProps>(), {})

const bgImage = computed(() => {
  if (!!props.bgImageSmall && sm.value) return props.bgImageSmall
  else if (!!props.bgImageMedium && md.value && !lg.value)
    return props.bgImageMedium

  return props.bgImageLarge
})
</script>

<template>
  <div class="default-banner-wrapper relative rounded-lg">
    <UtilsWrapperImage
      v-if="bgImage"
      v-bind="bgImage"
      :key="bgImage.image.url"
      class="
        wrapper-image-full-container-centered
        absolute
        inset-0
        z-0
        overflow-hidden
        rounded-lg
      "
    />
    <div
      class="
        relative
        inset-5
        top-0
        z-10
        flex flex-col
        gap-6
        px-6
        py-10
        md:gap-8
      "
      :class="{ 'md:flex-row md:flex-wrap md:justify-center': isLongVersion }"
    >
      <div
        v-for="({ title, iconType }, index) in banners"
        :key="`banner${index}`"
        class="flex items-center gap-2"
      >
        <UtilsIcon
          v-bind="iconsSrcMap[iconType]"
          color="tiffany-main"
          v-if="iconType"
          class="h-6 w-6"
        />

        <p v-if="title" class="beaver-medium md:pig-medium">
          {{ title }}
        </p>
      </div>
    </div>
  </div>
</template>
