import { Navigation, Pagination } from "swiper/modules"
import { CarouselProps } from "~/utils/carouselProps"

export const optionsSm: CarouselProps = {
  slidesPerView: "auto",
  spaceBetween: 16,
  pagination: {
    enabled: true
  },
  modules: [Pagination],
  theme: "pagination-tiffany-tiffany30"
}

export const optionsMd: CarouselProps = {
  slidesPerView: 1,
  spaceBetween: 24,
  grabCursor: true,
  navigation: true,
  modules: [Navigation],
  theme: "navigation-dark-middle"
}
