<script setup lang="ts">
import { Widget } from "@happyreact/vue"
import { QuestionForYouProps } from "./QuestionForYou.props"
import { navigateToLoginSignupPage } from "~/utils/loginSignup/loginSignup"

const isLogged = useIsLogged()
const { openModal } = useModal()

defineProps<QuestionForYouProps>()

const runtimeConfig = useRuntimeConfig()
const token = runtimeConfig.public.HAPPYREACT_FACES_TOKEN

const state = reactive<{ reaction: null | string }>({ reaction: null })

const handleReaction = (params: any) => {
  if (!params) {
    state.reaction = "alreadyReacted"
    return
  }

  state.reaction = params.icon
}

const handleClickIfNotLogged = () => {
  if (!isLogged.value) {
    navigateToLoginSignupPage()
  }
}
</script>

<template>
  <div :class="{ 'cursor-pointer': !isLogged }" @click="handleClickIfNotLogged">
    <div
      class="
        faces-wrapper
        flex
        min-h-[154px]
        transform-gpu
        flex-col
        items-center
        justify-center
        gap-4
        rounded-lg
        bg-white
        px-4
        py-5
        drop-shadow-01
        md:flex-row
        md:items-center
        md:justify-around
      "
      :class="{ 'pointer-events-none': !isLogged }"
    >
      <div class="triangle"></div>

      <p
        v-if="state.reaction"
        class="pig-bold md:elephant-bold text-green-main"
      >
        {{
          state.reaction === "alreadyReacted"
            ? $t("productPage.questionForYou.alreadyReacted")
            : $t("productPage.questionForYou.thanks")
        }}
      </p>
      <div v-else class="space-y-2">
        <p class="pig-bold md:elephant-bold text-green-main">
          {{ $t("productPage.questionForYou.title") }}
        </p>

        <UtilsMarkdown
          class="beaver md:pig"
          v-if="question"
          :content="question"
        />
      </div>

      <Widget
        v-if="!state.reaction"
        :token="token"
        :resource="resource"
        :onReaction="handleReaction"
        :onReactionsLimitReached="handleReaction"
      />
    </div>
  </div>
</template>

<style lang="scss">

.faces-wrapper {
  .hr-grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12px;
  }

  .hr-reaction {
    display: flex;
    flex-direction: row;

    &:before {
      content: "";
      width: 32px;
      height: 32px;
    }

    span {
      display: none;
    }
  }

  .hr-cell {
    &:nth-child(1) {
      .hr-reaction:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%23F7F8FA' d='M32 17.056c0 8.125-6.588 14.712-14.713 14.712-8.126 0-14.714-6.587-14.714-14.712S9.16 2.344 17.287 2.344C25.412 2.344 32 8.93 32 17.056Z'/%3E%3Cpath fill='%23247881' d='M22.321 11.623a1.78 1.78 0 1 1-3.56 0 1.78 1.78 0 0 1 3.56 0Zm-10.235 0a1.78 1.78 0 1 1-3.558 0 1.78 1.78 0 0 1 3.558 0ZM9.392 24.927a.733.733 0 0 1-.733-.733c0-3.166 2.547-5.74 5.677-5.74h2.472c3.131 0 5.678 2.56 5.678 5.708a.733.733 0 1 1-1.467 0c0-2.34-1.889-4.242-4.21-4.242h-2.473c-2.321 0-4.21 1.917-4.21 4.273a.733.733 0 0 1-.734.734Z'/%3E%3Cpath fill='%23247881' d='M15.447 31.12C6.93 31.12 0 24.193 0 15.676 0 7.16 6.93.23 15.447.23c8.518 0 15.447 6.93 15.447 15.445 0 8.517-6.93 15.446-15.447 15.446Zm0-29.423c-7.709 0-13.98 6.27-13.98 13.979 0 7.708 6.271 13.978 13.98 13.978 7.709 0 13.98-6.27 13.98-13.979 0-7.707-6.271-13.978-13.98-13.978Z'/%3E%3C/svg%3E%0A");
      }

      .hr-reaction:hover:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%23FA5861' d='M30 15.056c0 8.125-6.588 14.712-14.713 14.712C7.16 29.768.573 23.18.573 15.056S7.16.344 15.287.344C23.412.344 30 6.93 30 15.056Z'/%3E%3Cpath fill='%232B2B2B' d='M22.321 11.623a1.78 1.78 0 1 1-3.56 0 1.78 1.78 0 0 1 3.56 0Zm-10.235 0a1.78 1.78 0 1 1-3.558 0 1.78 1.78 0 0 1 3.558 0ZM9.392 24.927a.733.733 0 0 1-.733-.733c0-3.166 2.547-5.74 5.677-5.74h2.472c3.131 0 5.678 2.56 5.678 5.708a.733.733 0 1 1-1.467 0c0-2.34-1.889-4.242-4.21-4.242h-2.473c-2.321 0-4.21 1.917-4.21 4.273a.733.733 0 0 1-.734.734Z'/%3E%3Cpath fill='%23FA5861' d='M15.447 31.12C6.93 31.12 0 24.193 0 15.676 0 7.16 6.93.23 15.447.23c8.518 0 15.447 6.93 15.447 15.445 0 8.517-6.93 15.446-15.447 15.446Zm0-29.423c-7.709 0-13.98 6.27-13.98 13.979 0 7.708 6.271 13.978 13.98 13.978 7.709 0 13.98-6.27 13.98-13.979 0-7.707-6.271-13.978-13.98-13.978Z'/%3E%3C/svg%3E%0A");
      }
    }

    &:nth-child(2) {
      .hr-reaction:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%23F7F8FA' d='M32 17.039c0 8.01-6.494 14.502-14.505 14.502-8.011 0-14.505-6.492-14.505-14.502 0-8.01 6.494-14.504 14.505-14.504C25.505 2.535 32 9.03 32 17.04Z'/%3E%3Cpath fill='%23247881' d='M11.942 24.717a.723.723 0 0 1-.679-.972c.792-2.148 2.072-3.596 3.807-4.304 3.028-1.236 6.29.279 6.429.344a.723.723 0 0 1-.618 1.307c-.047-.021-2.85-1.304-5.272-.31-1.34.55-2.345 1.715-2.989 3.462a.723.723 0 0 1-.678.473Zm10.769-12.639a1.508 1.508 0 1 1-3.015 0 1.508 1.508 0 0 1 3.015 0Zm-11.267 0a1.508 1.508 0 1 1-3.015 0 1.508 1.508 0 0 1 3.015 0Z'/%3E%3Cpath fill='%23247881' d='M15.229 30.907C6.832 30.907 0 24.076 0 15.68S6.832.453 15.229.453c8.396 0 15.227 6.831 15.227 15.227s-6.83 15.227-15.227 15.227Zm0-29.008c-7.6 0-13.783 6.182-13.783 13.78 0 7.6 6.183 13.781 13.783 13.781 7.599 0 13.781-6.181 13.781-13.78 0-7.599-6.182-13.78-13.782-13.78Z'/%3E%3C/svg%3E%0A");
      }

      .hr-reaction:hover:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%23FFB050' d='M30 16.039c0 8.01-6.494 14.502-14.505 14.502C7.484 30.541.99 24.05.99 16.04S7.484 1.536 15.495 1.536C23.505 1.535 30 8.03 30 16.04Z'/%3E%3Cpath fill='%232B2B2B' d='M11.942 24.717a.723.723 0 0 1-.679-.972c.792-2.148 2.072-3.596 3.807-4.304 3.028-1.236 6.29.279 6.429.344a.723.723 0 0 1-.618 1.307c-.047-.021-2.85-1.304-5.272-.31-1.34.55-2.345 1.715-2.989 3.462a.723.723 0 0 1-.678.473Zm10.769-12.639a1.508 1.508 0 1 1-3.015 0 1.508 1.508 0 0 1 3.015 0Zm-11.267 0a1.508 1.508 0 1 1-3.015 0 1.508 1.508 0 0 1 3.015 0Z'/%3E%3Cpath fill='%23FFB050' d='M15.229 30.907C6.832 30.907 0 24.076 0 15.68S6.832.453 15.229.453c8.396 0 15.227 6.831 15.227 15.227s-6.83 15.227-15.227 15.227Zm0-29.008c-7.6 0-13.783 6.182-13.783 13.78 0 7.6 6.183 13.781 13.783 13.781 7.599 0 13.781-6.181 13.781-13.78 0-7.599-6.182-13.78-13.782-13.78Z'/%3E%3C/svg%3E%0A");
      }
    }

    &:nth-child(3) {
      .hr-reaction:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%23F7F8FA' d='M32 17.293C32 25.415 25.414 32 17.29 32 9.169 32 2.584 25.415 2.584 17.293S9.168 2.586 17.29 2.586C25.414 2.586 32 9.17 32 17.293Z'/%3E%3Cpath fill='%23247881' d='M22.712 21.715H8.82a.744.744 0 0 1 0-1.488h13.892a.744.744 0 1 1 0 1.488Zm-.387-10.311a1.78 1.78 0 1 1-3.56-.001 1.78 1.78 0 0 1 3.56 0Zm-10.231 0a1.78 1.78 0 1 1-3.559 0 1.78 1.78 0 0 1 3.559 0Z'/%3E%3Cpath fill='%23247881' d='M15.453 30.905C6.933 30.905 0 23.976 0 15.456S6.932.004 15.453.004c8.52 0 15.453 6.931 15.453 15.45 0 8.52-6.933 15.451-15.453 15.451Zm0-29.413c-7.7 0-13.965 6.263-13.965 13.963 0 7.699 6.265 13.963 13.965 13.963s13.964-6.264 13.964-13.963c0-7.7-6.264-13.963-13.964-13.963Z'/%3E%3C/svg%3E%0A");
      }

      .hr-reaction:hover:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%23FFDB3A' d='M30 15.293C30 23.415 23.414 30 15.29 30 7.169 30 .584 23.415.584 15.293S7.168.586 15.29.586C23.414.586 30 7.17 30 15.293Z'/%3E%3Cpath fill='%232B2B2B' d='M22.712 21.715H8.82a.744.744 0 0 1 0-1.488h13.892a.744.744 0 1 1 0 1.488Zm-.387-10.311a1.78 1.78 0 1 1-3.56-.001 1.78 1.78 0 0 1 3.56 0Zm-10.231 0a1.78 1.78 0 1 1-3.559 0 1.78 1.78 0 0 1 3.559 0Z'/%3E%3Cpath fill='%23FFDB3A' d='M15.453 30.905C6.933 30.905 0 23.976 0 15.456S6.932.004 15.453.004c8.52 0 15.453 6.931 15.453 15.45 0 8.52-6.933 15.451-15.453 15.451Zm0-29.413c-7.7 0-13.965 6.263-13.965 13.963 0 7.699 6.265 13.963 13.965 13.963s13.964-6.264 13.964-13.963c0-7.7-6.264-13.963-13.964-13.963Z'/%3E%3C/svg%3E%0A");
      }
    }

    &:nth-child(4) {
      .hr-reaction:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%23F7F8FA' d='M32 17.038c0 8.01-6.494 14.504-14.505 14.504-8.012 0-14.506-6.493-14.506-14.504 0-8.01 6.494-14.503 14.506-14.503C25.505 2.535 32 9.03 32 17.038Z'/%3E%3Cpath fill='%23247881' d='M15.467 24.718a9.829 9.829 0 0 1-3.835-.828.723.723 0 0 1 .618-1.308c.047.022 2.849 1.304 5.272.31 1.34-.55 2.345-1.715 2.989-3.462a.723.723 0 0 1 1.357.5c-.792 2.147-2.072 3.596-3.807 4.304a6.838 6.838 0 0 1-2.594.484Zm7.243-12.641a1.507 1.507 0 1 1-3.014 0 1.507 1.507 0 0 1 3.015 0Zm-11.266 0a1.508 1.508 0 1 1-3.015 0 1.508 1.508 0 0 1 3.015 0Z'/%3E%3Cpath fill='%23247881' d='M15.228 30.907C6.831 30.907 0 24.075 0 15.68S6.831.453 15.228.453c8.397 0 15.229 6.831 15.229 15.227s-6.832 15.227-15.229 15.227Zm0-29.008c-7.6 0-13.782 6.182-13.782 13.78 0 7.6 6.183 13.781 13.782 13.781 7.6 0 13.783-6.181 13.783-13.78 0-7.599-6.183-13.78-13.783-13.78Z'/%3E%3C/svg%3E");
      }

      .hr-reaction:hover:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%23CEED51' d='M30 15.038c0 8.01-6.494 14.504-14.505 14.504C7.483 29.542.989 23.05.989 15.038.99 7.028 7.483.535 15.495.535 23.505.535 30 7.03 30 15.038Z'/%3E%3Cpath fill='%232B2B2B' d='M15.467 24.718a9.829 9.829 0 0 1-3.835-.828.723.723 0 0 1 .618-1.308c.047.022 2.849 1.304 5.272.31 1.34-.55 2.345-1.715 2.989-3.462a.723.723 0 0 1 1.357.5c-.792 2.147-2.072 3.596-3.807 4.304a6.838 6.838 0 0 1-2.594.484Zm7.243-12.641a1.507 1.507 0 1 1-3.014 0 1.507 1.507 0 0 1 3.015 0Zm-11.266 0a1.508 1.508 0 1 1-3.015 0 1.508 1.508 0 0 1 3.015 0Z'/%3E%3Cpath fill='%23CEED51' d='M15.228 30.907C6.831 30.907 0 24.075 0 15.68S6.831.453 15.228.453c8.397 0 15.229 6.831 15.229 15.227s-6.832 15.227-15.229 15.227Zm0-29.008c-7.6 0-13.782 6.182-13.782 13.78 0 7.6 6.183 13.781 13.782 13.781 7.6 0 13.783-6.181 13.783-13.78 0-7.599-6.183-13.78-13.783-13.78Z'/%3E%3C/svg%3E%0A");
      }
    }

    &:nth-child(5) {
      .hr-reaction:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%23F7F8FA' d='M32 17.055c0 8.126-6.588 14.712-14.714 14.712-8.126 0-14.713-6.586-14.713-14.712 0-8.125 6.587-14.711 14.713-14.711S32 8.93 32 17.055Z'/%3E%3Cpath fill='%23247881' d='M22.321 11.623a1.78 1.78 0 1 1-3.558 0 1.78 1.78 0 0 1 3.558 0Zm-10.234 0a1.78 1.78 0 1 1-3.559 0 1.78 1.78 0 0 1 3.559 0Zm4.722 13.184h-2.472c-3.131 0-5.678-2.516-5.678-5.609a.733.733 0 1 1 1.467 0c0 2.322 1.85 4.142 4.21 4.142h2.473c2.36 0 4.21-1.79 4.21-4.073a.733.733 0 1 1 1.467 0c0 3.106-2.494 5.54-5.677 5.54Z'/%3E%3Cpath fill='%23247881' d='M15.447 31.12C6.93 31.12 0 24.192 0 15.675 0 7.16 6.93.23 15.447.23s15.447 6.93 15.447 15.445c0 8.517-6.93 15.445-15.447 15.445Zm0-29.423c-7.709 0-13.98 6.27-13.98 13.978s6.271 13.979 13.98 13.979c7.709 0 13.98-6.27 13.98-13.979 0-7.707-6.271-13.978-13.98-13.978Z'/%3E%3C/svg%3E%0A");
      }

      .hr-reaction:hover:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%2372CF63' d='M30 15.056c0 8.125-6.588 14.711-14.714 14.711C7.16 29.767.573 23.181.573 15.056.573 6.93 7.16.344 15.286.344S30 6.93 30 15.056Z'/%3E%3Cpath fill='%232B2B2B' d='M22.321 11.623a1.78 1.78 0 1 1-3.558 0 1.78 1.78 0 0 1 3.558 0Zm-10.234 0a1.78 1.78 0 1 1-3.559 0 1.78 1.78 0 0 1 3.559 0Zm4.722 13.184h-2.472c-3.131 0-5.678-2.516-5.678-5.609a.733.733 0 1 1 1.467 0c0 2.322 1.85 4.142 4.21 4.142h2.473c2.36 0 4.21-1.79 4.21-4.073a.733.733 0 1 1 1.467 0c0 3.106-2.494 5.54-5.677 5.54Z'/%3E%3Cpath fill='%2372CF63' d='M15.447 31.12C6.93 31.12 0 24.192 0 15.675 0 7.16 6.93.23 15.447.23s15.447 6.93 15.447 15.445c0 8.517-6.93 15.445-15.447 15.445Zm0-29.423c-7.709 0-13.98 6.27-13.98 13.978s6.271 13.979 13.98 13.979c7.709 0 13.98-6.27 13.98-13.979 0-7.707-6.271-13.978-13.98-13.978Z'/%3E%3C/svg%3E%0A");
      }
    }
  }

  .hr-powered-by {
    display: none;
  }
}

</style>
