export type IconType = keyof typeof iconsSrcMap

export const iconsSrcMap = {
  fileText: { name: "FileText.svg" },
  box: { name: "Box.svg" },
  pharmacy: { name: "OutlinePharmacy.svg" },
  folder: { name: "Folder.svg" },
  bookOpen: { name: "BookOpen.svg" },
  alertTriangle: { name: "AlertTriangle.svg" },
  info: { name: "Info.svg" },
  alertCircle: { name: "AlertCircle.svg" },
  sync: { name: "Sync.svg" }
}
