<script setup lang="ts">
import { AlreadyPurchasedProps } from "./AlreadyPurchased.props"

const props = defineProps<AlreadyPurchasedProps>()

const iconsSrcMap = {
  heart: { name: "Heart.svg", color: "tiffany-main" },
  clock: { name: "Clock.svg", color: "tiffany-main" }
}

const currentIcon = computed(() =>
  props.iconType ? iconsSrcMap[props.iconType] : undefined
)
</script>

<template>
  <div
    class="
      product_page-utils-already_purchased
      flex
      items-center
      gap-2
      rounded-lg
      bg-yellow-10
      p-4
      md:px-5
      md:py-3
    "
  >
    <UtilsIcon
      class="h-6 w-6 shrink-0 text-tiffany-main"
      v-if="currentIcon"
      v-bind="currentIcon"
    />
    <p class="beaver md:dolphin text-slate-main">
      {{
        isRecurrent
          ? $t("productPage.detail.recurrentPurchese")
          : $t("productPage.detail.youPurchesedThisProductOn")
      }}
      <strong>
        {{ date }}
      </strong>
    </p>
  </div>
</template>
