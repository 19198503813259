<script setup lang="ts">
import { DescriptionProps } from "./Description.props"
import { useTimeoutFn } from "@vueuse/core"

const props = defineProps<DescriptionProps>()

const dayjs = useDayjs()

const handleDownloadLeaflet = () => {
  if (props.infos) navigateTo(props.infos.leafletSlug, { external: true })
}

// let closeFn: () => void

// onMounted(() => {
//   useTimeoutFn(() => {
//     if (closeFn) closeFn()

//     document.documentElement.scrollTop = 0
//   }, 10)
// })
</script>

<template>
  <div class="container-product_page-description">
    <h2 class="dolphin-bold md:elephant-bold mb-4">
      {{ $t("productPage.description.infosTitle") }}
    </h2>

    <!-- Property Table -->
    <div v-if="infos" class="divide-y divide-grey-100">
      <UtilsPropertyRow
        v-if="infos.activePrinciple"
        :title="$t('productPage.description.infos.activePrinciple')"
        :description="infos.activePrinciple"
      >
        <template #icon
          ><UtilsIcon name="OutlinePharmacy.svg" color="black-80"
        /></template>
      </UtilsPropertyRow>
      <UtilsPropertyRow
        v-if="infos.howToUse"
        :title="$t('productPage.description.infos.howToUse')"
        :description="infos.howToUse"
      >
        <template #icon
          ><UtilsIcon name="BookOpen.svg" color="black-80"
        /></template>
      </UtilsPropertyRow>
      <UtilsPropertyRow
        v-if="infos.dosage"
        :title="$t('productPage.description.infos.dosage')"
        :description="infos.dosage"
      >
        <template #icon
          ><UtilsIcon name="ColorPicker.svg" color="black-80"
        /></template>
      </UtilsPropertyRow>
      <UtilsPropertyRow
        v-if="infos.dosageType"
        :title="$t('productPage.description.infos.dosageType')"
        :description="infos.dosageType"
      >
        <template #icon
          ><UtilsIcon name="Archive.svg" color="black-80"
        /></template>
      </UtilsPropertyRow>
      <UtilsPropertyRow
        v-if="infos.leafletSlug"
        :title="$t('productPage.description.infos.leaflet')"
        :description="`[${$t('productPage.description.checkLeaflet')}](${
          infos.leafletSlug
        })`"
      >
        <template #icon
          ><UtilsIcon name="Post.svg" color="black-80"
        /></template>
      </UtilsPropertyRow>
      <UtilsPropertyRow
        v-if="infos.category"
        :title="$t('productPage.description.infos.category')"
        :description="infos.category"
      >
        <template #icon
          ><UtilsIcon name="Folder.svg" color="black-80"
        /></template>
      </UtilsPropertyRow>
      <UtilsPropertyRow
        v-if="infos.unitOfMeasure"
        :title="$t('productPage.description.infos.unitOfMeasure')"
        :description="infos.unitOfMeasure"
      >
        <template #icon
          ><UtilsIcon name="Droplet.svg" color="black-80"
        /></template>
      </UtilsPropertyRow>
      <UtilsPropertyRow
        v-if="infos?.correspondingGenericDrug?.productName"
        :title="$t('productPage.description.infos.correspondingGenericDrug')"
        :description="`[${infos.correspondingGenericDrug.productName}](${infos.correspondingGenericDrug.slug})`"
      >
        <template #icon
          ><UtilsIcon name="OutlineMedicine.svg" color="black-80"
        /></template>
      </UtilsPropertyRow>
      <UtilsPropertyRow
        v-if="infos.dueDate"
        :title="$t('productPage.description.infos.dueDate')"
        :description="dayjs(infos.dueDate, 'YYYY/MM/DD').format('DD/MM/YYYY')"
      >
        <template #icon
          ><UtilsIcon name="Info.svg" color="black-80"
        /></template>
      </UtilsPropertyRow>
      <UtilsPropertyRow
        v-if="infos.productCode"
        :title="$t('productPage.description.infos.productCode')"
        :description="infos.productCode"
      >
        <template #icon><UtilsIcon name="Box.svg" color="black-80" /></template>
      </UtilsPropertyRow>
      <UtilsPropertyRow
        v-if="infos.firm?.name"
        :title="$t('productPage.description.infos.firm')"
        :description="`[${infos.firm.name}](${infos.firm.slug})`"
      >
        <template #icon
          ><UtilsIcon name="Home.svg" color="black-80"
        /></template>
      </UtilsPropertyRow>
    </div>

    <!-- Product Description -->
    <Disclosure
      v-slot="{ open, close }"
      defaultOpen
      v-if="descriptions.length || props.infos.leafletSlug"
    >
      <!-- <div class="hidden">{{ (closeFn = close) }}</div> -->
      <DisclosureButton
        class="mt-10 flex w-full justify-between border-t border-black-20 py-6"
      >
        <h3 class="dolphin-bold md:elephant-bold">
          {{ $t("productPage.description.descriptionTitle") }}
        </h3>

        <UtilsIcon
          name="ChevronDown.svg"
          class="h-6 w-6"
          :class="open && 'rotate-180 transform'"
        />
      </DisclosureButton>
      <DisclosurePanel class="text-gray-500">
        <div v-if="descriptions.length" class="space-y-6">
          <UtilsProperty
            v-for="desc in descriptions"
            v-bind="desc"
            markup="html"
          />
        </div>

        <UtilsButton
          v-if="props.infos.leafletSlug"
          @click="handleDownloadLeaflet"
          class="
            custom-btn-underlined custom-btn-only-beaver custom-btn-no-padding
            mb-6
            mt-3
          "
          theme="ghost-green"
          :text="$t('productPage.description.checkLeaflet')"
          order="last"
        >
          <template #icon>
            <UtilsIcon name="Download.svg" color="green-main" />
          </template>
        </UtilsButton>
        <ProductPageUtilsMinisterialBanner
          class="mb-10"
          v-if="ministerialBanner"
          v-bind="ministerialBanner"
        />
      </DisclosurePanel>
    </Disclosure>

    <!-- Important Infos -->
    <Disclosure
      v-slot="{ open }"
      defaultOpen
      v-if="importantInfos.length || props.infos.leafletSlug"
    >
      <DisclosureButton
        class="flex w-full justify-between border-t border-black-20 py-6"
      >
        <span class="dolphin-bold md:elephant-bold">
          {{ $t("productPage.description.importantInfosTitle") }}
        </span>

        <UtilsIcon
          name="ChevronDown.svg"
          class="h-6 w-6"
          :class="open && 'rotate-180 transform'"
        />
      </DisclosureButton>
      <DisclosurePanel class="text-gray-500">
        <div v-if="importantInfos.length" class="space-y-6">
          <UtilsProperty v-for="info in importantInfos" v-bind="info" />
        </div>
        <UtilsButton
          v-if="props.infos.leafletSlug"
          @click="handleDownloadLeaflet"
          class="
            custom-btn-underlined custom-btn-only-beaver custom-btn-no-padding
            mb-6
            mt-3
          "
          theme="ghost-green"
          :text="$t('productPage.description.checkLeaflet')"
          order="last"
        >
          <template #icon>
            <UtilsIcon name="Download.svg" color="green-main" />
          </template>
        </UtilsButton>
      </DisclosurePanel>
    </Disclosure>

    <div class="mb-6 border-b border-black-20 md:mb-0"></div>
    <OrganismsCartBannerDeliveryPayments
      v-if="hasFaqs"
      class="py-4 md:py-6"
      v-bind="{
        isOneBannerFull: true,
        banners: [
          {
            title: $t('productPage.description.bannerTitle'),
            text: $t('productPage.description.bannerText'),
            isCentered: true,
            iconType: 'comment'
          }
        ]
      }"
    />
  </div>
</template>

<style scoped lang="scss">

:focus-visible {
  outline: none;
}

</style>
