<script setup lang="ts">
import { NotSellableProps } from "./NotSellable.props"

const { productCode } = defineProps<NotSellableProps>()

const emit =
  defineEmits<{
    (e: "handleScrollToAlternatives"): void
  }>()

const handleScrollToAlternatives = async () => {
  emit("handleScrollToAlternatives")
}

</script>

<template>
  <div class="flex flex-wrap bg-grey-main px-4 py-4">
    <h3 class="dolphin-bold mb-4 flex w-full items-center">
      <UtilsIcon
        name="Warning.svg"
        class="mr-2 inline-block h-6 w-6"
        fetch-priority="high"
        :preload="true"
      />
      {{ $t("productPage.purchaseType.notSellable.title") }}
    </h3>

    <UtilsMarkdown
      class="beaver markdown__strong-beaver-bold w-full"
      :key="'notSellableText'"
      :class="'mb-4'"
      :content="$t('productPage.purchaseType.notSellable.subtitle_1')"
    />

    <UtilsMarkdown
      class="beaver markdown__strong-beaver-bold w-full"
      :key="'notSellableText'"
      :class="'mb-4'"
      :content="$t('productPage.purchaseType.notSellable.subtitle_2')"
    />

    <UtilsButton
        type="submit"
        class="mb-4 w-full"
        :text="$t('productPage.purchaseType.notSellable.similarProduct')"
        @click="handleScrollToAlternatives"
      />

  </div>
</template>
