<script setup lang="ts">
import { DetailProps } from "./Detail.props"
import { smOptions, lgOptions, thumbsOptions } from "./Detail.carouselOptions"
import { formatMoney, discountPercentage } from "~/utils/formatters"
import { sm } from "~/utils/breakpoints"
import { AlreadyPurchasedProps } from "~/components/ProductPage/Utils/AlreadyPurchased.props"
import { useUserStore } from "~/stores/user"
import { storeToRefs } from "pinia"
import { customPaths } from "~/utils/constants"
import type { ProductProps as productBadge } from "../Badge/Product.props"
import { productUtils } from "~/utils/product"

const dayjs = useDayjs()

declare class ListResponse<R> extends Array<R> {}

interface Order {
  placed_at?: string
  line_items?: LineItem[]
}

interface LineItem {
  sku_code?: string
}

const props = withDefaults(defineProps<DetailProps>(), {
  stars: 0,
  ratings: 0
})

const emit =
  defineEmits<{
    (e: "onShare", value: string): void
    (e: "onOpenModalGallery", value: number): void
  }>()

const carouselOptions = ref(smOptions)

const discount = discountPercentage(props.price, props.oldPrice)

const isLogged = useIsLogged()

const handleReviews = () => {
  if (props.ratings <= 0) {
    return
  }
  const element = document.querySelector("#container-reviews")

  element?.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "start"
  })
  //navigateTo("#container-reviews")
}

const mainSwiper = ref<null | any>(null)
const thumbsSwiper = ref<null | any>(null)
const activeIndex = ref(0)

const setThumbsSwiper = (swiper: any) => {
  thumbsSwiper.value = swiper
}

const slideChanged = (swiper: any) => {
  activeIndex.value = swiper.activeIndex
}

const onSwiper = (swiper: any) => {
  mainSwiper.value = swiper
}

const hoverThumb = (index: number) => {
  mainSwiper?.value?.slideTo(index)
}

const toggleGalleryModal = () => {
  isGalleryModalOpen.value = !isGalleryModalOpen.value
}

const isGalleryModalOpen = ref(false)

const hoverThumbModal = (index: number) => {
  activeIndex.value = index
}

const MAX_CHART = 300

const maxLength = ref(MAX_CHART)
const isShowMore = ref(true)

const truncateString = (str: string, num: number) => {
  if (str?.length <= num) {
    return str
  }
  return str.slice(0, num) + "..."
}
const isVisibleShowMore = (description: string) => {
  return description?.length > MAX_CHART
}

const toggleFullDescription = (description: string) => {
  isShowMore.value = !isShowMore.value
  maxLength.value = isShowMore.value ? MAX_CHART : description?.length
}

const { initBuyAgain, buyAgainProducts } = await useBuyAgain()
const orders = ref<ListResponse<Order>>([])
const { $cl } = useNuxtApp()
const userStore = useUserStore()
const { userId } = storeToRefs(userStore)

onMounted(async () => {
  await initBuyAgain()
  if (isLogged.value) {
    orders.value = await getAllOrders()
  }
})

const isProductAlreadyBought = computed(() => {
  if (!buyAgainProducts.value?.length || !isLogged.value) return false

  return buyAgainProducts.value.some(
    (p: any) => p?.productCode === props.productId
  )
})

// TODO: Pagination
const getAllOrders = async () => {
  try {
    const orders = await $cl?.customers.orders(userId.value, {
      include: ["line_items"],
      fields: { orders: ["placed_at", "line_items"], line_items: ["sku_code"] },
      filters: {
        status_not_in: "draft,pending"
      },
      sort: { placed_at: "desc" },
      pageSize: 20
    })

    return orders
  } catch (error) {
    console.error("Error retrieving orders:", error)
    throw error // Rethrow the error to be handled by the caller
  }
}

const getMostRecentOrderPlacedAt = (
  orders: ListResponse<Order>,
  skuCode: string
) => {
  const filteredOrders = orders.filter((order) => {
    if (!order?.line_items?.length) return false
    return order.line_items.some((item) => item.sku_code === skuCode)
  })

  if (!filteredOrders?.length) {
    return null // Return null if no orders match the SKU code
  }

  filteredOrders.sort((a, b) => {
    const placedAtA = a ? dayjs(a.placed_at ?? "") : dayjs()
    const placedAtB = b ? dayjs(b.placed_at ?? "") : dayjs()
    return placedAtB.valueOf() - placedAtA.valueOf()
  })

  return filteredOrders[0].placed_at // Return the most recent placed_at value
}

const alreadyPurchasedProps = computed(():
  | AlreadyPurchasedProps
  | undefined => {
  if (!isProductAlreadyBought.value || !orders.value?.length) {
    return undefined
  }

  const date = getMostRecentOrderPlacedAt(orders.value, props.productId)
  if (!date) {
    return undefined
  }

  return {
    date: dayjs(date).format("DD MMMM YYYY"),
    iconType: "heart"
  }
})

const starsRating = computed(() => props.stars)

const isQuantityInRange = computed(
  () =>
    props.stockQuantity && props.stockQuantity > 0 && props.stockQuantity <= 5
)

const isInStock = computed(() => (props?.stockQuantity ?? 0) > 0)

const productBadges = computed((): productBadge[] | undefined => {
  const lastPiecesBagde: productBadge = { theme: "ULTIMI PEZZI" }

  return props.badges?.length || isQuantityInRange.value
    ? [
        ...(props.badges || []),
        ...(isQuantityInRange.value ? [lastPiecesBagde] : [])
      ]
    : undefined
})
</script>

<template>
  <div class="product_page-detail md:flex md:gap-6">
    <!-- Modal gallery -->
    <ClientOnly>
      <Dialog
        :open="isGalleryModalOpen"
        @close="toggleGalleryModal"
        class="relative z-50"
      >
        <!-- Full-screen scrollable container -->
        <div class="fixed inset-0 overflow-y-auto md:overflow-hidden">
          <!-- The actual dialog panel -->
          <DialogPanel class="min-h-screen w-full bg-white">
            <button
              :tabindex="sm ? '1' : '2'"
              @click="toggleGalleryModal"
              class="
                fixed
                right-4
                top-4
                z-20
                rounded
                border-2 border-solid border-black-main
                outline-none
                md:right-[71px]
                md:top-6
                lg:right-[228px]
              "
            >
              <UtilsIcon name="Close.svg" class="h-6 w-6 md:h-8 md:w-8" />
            </button>

            <div
              class="md:grid-standard items-center md:h-screen"
              v-if="gallery"
            >
              <div
                class="
                  flex flex-col
                  md:my-8
                  md:w-[110px]
                  md:gap-4
                  md:overflow-y-auto
                  md:py-5
                  md:scrollbar-hide
                "
              >
                <UtilsWrapperImage
                  v-for="(img, index) in gallery"
                  :="img"
                  class="
                    wrapper-image-fit-contain
                    md:wrapper-image-fixed-76
                    transform-gpu
                    border-2 border-transparent
                    outline-none
                    drop-shadow-01
                  "
                  @mouseover="hoverThumbModal(index)"
                  @click="hoverThumbModal(index)"
                  @keypress.enter="hoverThumbModal(index)"
                  :tabindex="sm ? '2' : '1'"
                  sizes="sm:100vw md:100vw lg:100px xl:100px"
                />
              </div>

              <div class="col-span-8 col-start-3 hidden h-screen py-4 md:block">
                <UtilsWrapperImage
                  class="wrapper-image-fit-contain"
                  :key="activeIndex"
                  :="gallery[activeIndex]"
                  sizes="sm:400px md:800px lg:1000px xl:1200px"
                />
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </ClientOnly>

    <!-- Desktop carousel -->
    <div class="relative hidden w-full md:block md:w-1/2">
      <div class="relative mb-4 border border-grey-100">
        <!-- Badges desktop -->
        <div
          v-if="
            productBadges &&
            (isInStock || productUtils.isSellableStatus(sellabilityStatus))
          "
          class="absolute left-2 right-0 top-2 z-[9] space-x-2"
        >
          <BadgeProduct v-for="badge in productBadges" v-bind="badge" />
        </div>

        <UtilsIcon
          name="Expand.svg"
          v-if="gallery?.length"
          class="absolute bottom-3 right-4 z-10 cursor-pointer"
          @click="toggleGalleryModal"
          fetch-priority="high"
          :preload="true"
        />

        <AppCarousel
          v-if="gallery?.length"
          :swiper-props="lgOptions"
          :items="gallery"
          :thumbs="{ swiper: thumbsSwiper }"
          @slideChange="slideChanged"
          @swiper="onSwiper"
        >
          <template #default="{ item, slideIndex }">
            <div @click="toggleGalleryModal" class="cursor-zoom-in">
              <UtilsWrapperImage
                class="wrapper-image-fit-contain wrapper-image-fixed-368"
                @click="emit('onOpenModalGallery', slideIndex)"
                v-bind="item"
                :imgAttrs="slideIndex === 0 ? { fetchpriority: 'high' } : {}"
                :preload="slideIndex === 0"
                sizes="sm:500px"
              />
            </div>
          </template>
        </AppCarousel>
        <div v-else class="flex h-[368px] w-full items-center justify-center">
          <UtilsIcon
            name="DefaultProductImage.svg"
            class="h-[180px] w-[180px]"
            fetch-priority="high"
            :preload="true"
          />
        </div>
      </div>

      <AppCarousel
        v-if="gallery?.length > 1"
        :swiper-props="thumbsOptions"
        :items="gallery"
        slide-classes="flex items-center justify-center py-5 "
        :theme="thumbsOptions.theme"
        watch-slides-progress
        @swiper="setThumbsSwiper"
      >
        <template #default="{ item, slideIndex, isVisible }">
          <div class="active-thumb">
            <UtilsWrapperImage
              v-bind="item"
              @mouseover="hoverThumb(slideIndex)"
              class="
                wrapper-image-fixed-76 wrapper-image-fit-contain
                transform-gpu
                cursor-pointer
                border-2 border-transparent
                drop-shadow-01
              "
              :class="{ invisible: !isVisible }"
            />
          </div>
        </template>
      </AppCarousel>
    </div>

    <div class="w-full md:w-1/2">
      <ProductPageUtilsAlreadyPurchased
        v-if="isProductAlreadyBought && alreadyPurchasedProps"
        v-bind="alreadyPurchasedProps"
        class="mb-4"
      />

      <h1 v-if="title" class="beaver-bold md:dolphin-bold mb-4">
        {{ title }}
      </h1>

      <!-- Mobile carousel -->
      <div class="relative mb-1 h-[333px] overflow-hidden md:hidden">
        <div v-if="gallery?.length" class="relative z-0">
          <AppCarousel
            class="carousel-overflowed"
            :swiper-props="carouselOptions"
            :items="gallery"
            :theme="carouselOptions.theme"
            :key="carouselOptions.spaceBetween"
          >
            <template #default="{ item, slideIndex }">
              <UtilsWrapperImage
                :imgAttrs="slideIndex === 0 ? { fetchpriority: 'high' } : {}"
                v-bind="item"
                sizes="sm:500px"
                class="wrapper-image-fit-contain wrapper-image-fixed-288"
                @click="toggleGalleryModal"
              />
            </template>
          </AppCarousel>
        </div>
        <div v-else class="flex h-full w-full items-center justify-center">
          <UtilsIcon
            name="DefaultProductImage.svg"
            class="h-[180px] w-[180px]"
            fetch-priority="high"
            :preload="true"
          />
        </div>

        <div
          class="absolute left-0 right-0 top-0 flex items-start justify-between"
        >
          <!-- Badges mobile -->
          <div
            v-if="
              productBadges &&
              (isInStock || productUtils.isSellableStatus(sellabilityStatus))
            "
            class="flex flex-wrap gap-x-2 gap-y-1"
          >
            <BadgeProduct v-for="badge in productBadges" v-bind="badge" />
          </div>
          <!-- Stars mobile -->
          <div
            class="
              stars-container
              flex flex-1
              items-center
              justify-end
              gap-1
              pr-2
              pt-2
            "
            v-if="starsRating > 0 || ratings > 0"
          >
            <UtilsStars
              v-if="starsRating >= 0"
              :stars="starsRating"
              :preload-icons="true"
            />
            <span
              v-if="ratings >= 0"
              class="mouse-medium-underlined cursor-pointer"
              :class="{ 'cursor-pointer': ratings > 0 }"
              @click="handleReviews"
              tabindex="0"
            >
              {{ ratings }}
            </span>
          </div>
        </div>
        <UtilsIcon
          name="Expand.svg"
          v-if="gallery?.length"
          class="absolute bottom-3 right-3 z-10 cursor-pointer"
          @click="toggleGalleryModal"
          fetch-priority="high"
          :preload="true"
        />
      </div>

      <!-- Prices -->
      <div
        class="mb-1 flex items-center gap-2 md:mb-0"
        v-if="isInStock || productUtils.isSellableStatus(sellabilityStatus)"
      >
        <BadgeProduct
          v-if="discount && discount > 0"
          theme="SCONTO"
          :text="`-${discount}%`"
        />
        <span v-if="price" class="elephant-bold md:mammoth-bold">
          {{ formatMoney(price) }}
        </span>
        <span
          v-if="isGreaterThan(oldPrice, price)"
          class="beaver-strikethrough text-black-80"
        >
          {{ formatMoney(oldPrice) }}
        </span>
      </div>

      <p
        class="beaver mb-2 md:mb-4"
        v-if="isInStock || productUtils.isSellableStatus(sellabilityStatus)"
      >
        {{ $t("productDetails.taxIncluded") }}
      </p>

      <!-- Expiration Date -->
      <div
        v-if="
          expirationDate &&
          badges?.find((el) => el.theme.toUpperCase() === 'FUORI TUTTO')
        "
        class="
          mb-2
          flex
          items-center
          gap-2
          rounded-lg
          bg-orange-10
          px-3
          py-[10px]
          md:mb-4
          md:px-4
        "
      >
        <UtilsIcon name="Calendar.svg" color="orange-main" class="h-6 w-6" />
        <span class="beaver-medium text-sm text-slate-main">
          {{ $t("productPage.detail.productExpiration") }}
          <span class="beaver-bold text-black-main">{{
            dayjs(props.expirationDate).format("DD/MM/YYYY")
          }}</span>
        </span>
      </div>

      <!-- Desktop ratings -->

      <SkeletonAnimatedPlaceholder
        v-if="pendingRatings"
        height="24px"
        width="100%"
        class="mb-4 hidden md:block"
      />

      <div
        class="stars-container mb-4 hidden items-center gap-1 md:flex"
        v-if="starsRating > 0 || ratings > 0"
      >
        <UtilsStars
          v-if="starsRating >= 0"
          :stars="starsRating"
          :size="6"
          :preload-icons="true"
        />

        <span
          v-if="ratings >= 0"
          class="mouse-medium-underlined text-green-main hover:text-green-300"
          :class="{
            'cursor-pointer': ratings > 0
          }"
          @click="handleReviews"
          tabindex="0"
        >
          {{ ratings }} {{ $t("productPage.detail.reviews", ratings) }}
        </span>
      </div>

      <!-- Deductable tax -->
      <div
        v-if="isDeductable"
        class="
          mb-2
          flex
          items-center
          gap-2
          rounded-lg
          bg-yellow-10
          px-3
          py-[6px]
          md:mb-4
          md:px-4
        "
      >
        <UtilsIcon
          name="Percent.svg"
          color="yellow-main"
          class="h-6 w-6"
          fetch-priority="high"
          :preload="true"
        />
        <span class="beaver-medium md:pig-medium text-slate-main">
          {{ $t("productPage.detail.deductableProduct") }}
        </span>
      </div>

      <!-- Description -->
      <div class="md:mb-4">
        <p v-if="description" class="beaver mb-2 text-black-80">
          {{ truncateString(description, maxLength) }}
        </p>

        <UtilsButton
          v-if="isVisibleShowMore(description as string)"
          @click="toggleFullDescription(description as string)"
          theme="link-green"
          class="
            custom-btn-no-padding custom-btn-start custom-btn-beaver-medium
          "
          :text="
            isShowMore
              ? $t('productTile.description.readMore')
              : $t('productTile.description.readLess')
          "
          order="last"
        >
        </UtilsButton>

        <div v-if="pills" class="flex flex-wrap gap-2 pt-4">
          <BadgePill v-for="pill in pills" v-bind="pill" />
        </div>
      </div>

      <UtilsShare is-product class="w-fit">
        <template #default>
          <UtilsButton
            class="
              custom-btn-no-padding
              float-right
              mb-4
              mt-4
              md:float-none
              md:mb-0
              md:mt-0
            "
            theme="link-green"
            :text="$t('productPage.detail.share')"
            tabindex="0"
          >
            <template #icon>
              <UtilsIcon
                name="Share.svg"
                color="green-main"
                class="h-5 w-5"
                fetch-priority="high"
                :preload="true"
              />
            </template>
          </UtilsButton>
        </template>
      </UtilsShare>

      <BannerFirstTimeHere v-if="!isLogged" class="!hidden md:!block" />
    </div>
  </div>
</template>

<style scoped lang="scss">

.swiper-slide-thumb-active {
  .active-thumb {
  }
}

</style>
