import { Pagination, Navigation, Autoplay, Thumbs } from "swiper/modules"
import { CarouselProps } from "~/utils/carouselProps"

export const smOptions: CarouselProps = {
  slidesPerView: 1,
  spaceBetween: 8,
  pagination: {
    enabled: true
  },
  modules: [Pagination],
  theme: "pagination-tiffany-tiffany30"
}

export const lgOptions: CarouselProps = {
  slidesPerView: 1,
  spaceBetween: 50,
  navigation: false,
  theme: "custom",
  autoplay: {
    delay: 3000
  },
  modules: [Autoplay, Thumbs]
}

export const thumbsOptions: CarouselProps = {
  slidesPerView: 3,
  navigation: true,
  spaceBetween: 20,
  theme: "navigation-secondary",
  modules: [Navigation]
}
